// STYLE DE BASE SUR LA BALISE BODY

body{
  font-family: "DM Sans", sans-serif;
  color: $color-text-normal;
  background-color: $color-bg-page;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 1920px;
  cursor: url(../images/cursor.png) 10 10, auto;
  //transform : translateY(0);


  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  transition: all 1s ease-in-out;

  &::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  div#noScrolling{
    position: fixed;
    overflow-x: hidden;
    max-width: 100vw;
    transition: all 1s ease-in-out;
  }

  p{
    margin: 0;
    font-weight: $fw-light;
    font-size: rem(18);
    line-height: rem(28);
    font-family: $ff-secondaire;

  }
}

@media only screen and (max-width: 375px) {
  body{
    max-width: 375px;

    p{
      font-size: rem(16);
      line-height: rem(22);
    }
  }
}



