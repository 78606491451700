section.contactAccueil{
  background-color: $color-black;
  height: 100vh;
  div.wrapper{
    display: flex;
    padding: rem(50) 0 rem(100) 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    h1{
      display: inline-block;
      text-align: center;
      color: $color-red;
      padding: 0;
      margin: 0;
      font-size: rem(200);
      line-height: rem(170);
      font-weight: $fw-regular;
    }

    h5{
      color: white;
    }

    p{
      margin-top: 3vh;
      width: 50%;
      align-self: flex-start;
      color: white;
    }

    ul{
      display: flex;
      align-self: flex-start;
      padding: 0;
      list-style-type: none;
      li{
        margin-right: rem(100);
        display: flex;
        align-items: center;
        color: white;
        font-size: rem(20);
      }
      img{
        margin-right: rem(20);
        display: inline;
      }
      &.mediaSociauxContact{
        display: flex;
        justify-content: space-between;
        align-self: center;
        width: 50%;
        li{
          margin-right: 0;
          font-size: rem(60);
          color: $color-bg-page;
          transition: all, 0.2s ease-in-out;
          &:hover{
            color: $color-red;
            transform: rotate(30deg);
          }
        }
      }
    }

  }
}

@media only screen and (min-width: $breakpoint-cellphone) and (max-width : 450px) {
  section.contactAccueil {
    background-color: $color-black;
    height: 100vh;

    div.wrapper {
      display: flex;
      padding: rem(50) 0 rem(100) 0;
      height: 100%;
      flex-direction: column;
      align-items: center;

      h1 {
        display: inline;
        text-align: center;
        color: $color-red;
        padding: 0;
        margin: 0;
        font-size: rem(90);
        line-height: rem(60);
        font-weight: $fw-regular;
      }

      h2{
        text-align: center;
        margin: auto;
        display: inline-block;
      }

      h5 {
        color: white;
        margin-top: rem(5);
      }
      p{
        text-align: center;
        width: 100%;
      }

      ul{
        display: block;
        align-self: center;
        padding: 0;
        list-style-type: none;
        margin-bottom: rem(5);
        li{
          margin: rem(10)  0 0;
          display: block;
          align-items: center;
          text-align: center;
          color: white;
          font-size: rem(20);
        }
        img{
          margin-right: rem(20);
          display: inline;
        }
        &.mediaSociauxContact{
          display: flex;
          justify-content: space-between;
          align-self: center;
          width: 90%;
          margin: 10% 0;
          li{
            margin-right: 0;
            font-size: rem(40);
            color: $color-bg-page;
            transition: all, 0.2s ease-in-out;
            &:hover{
              color: $color-red;
              transform: rotate(30deg);
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width:451px) and(max-width: $breakpoint-tablette)  {
  section.contactAccueil{
    background-color: $color-black;
    height: 100vh;
    div.wrapper{
      display: flex;
      padding: rem(50) 0 rem(100) 0;
      height: 100%;
      flex-direction: column;
      align-items: center;

      h2{
        display: block;
      }
      h1{
        display: block;
        text-align: left;
        color: $color-red;
        padding: 0;
        margin: 0;
        font-size: rem(175);
        line-height: rem(125);
        font-weight: $fw-regular;
      }

      h5{
        color: white;
      }

      p{
        margin-top: 3vh;
        width: 100%;
        text-align: center;
        align-self: flex-start;
        color: white;
      }

      ul{
        display: flex;
        align-self: center;
        padding: 0;
        list-style-type: none;
        li{
          margin-right: rem(30);
          margin-left: rem(30);
          display: flex;
          align-items: center;
          color: white;
          font-size: rem(20);
        }
        img{
          margin-right: rem(10);
          display: inline;
        }
        &.mediaSociauxContact{
          display: flex;
          justify-content: space-between;
          align-self: center;
          width: 80%;
          li{
            margin: 0;
            font-size: rem(60);
            color: $color-bg-page;
            transition: all, 0.2s ease-in-out;
            &:hover{
              color: $color-red;
              transform: rotate(30deg);
            }
          }
        }
      }
    }
  }
}