section.mesProjets {
  div.unProjet {
    height: 100vh;
    padding: 20px 0;



    div.maGrid {
      margin-top: 5vh;
      display: grid;
      width: 100%;
      height: 65vh;
      grid-template-columns: repeat(11, 1fr);
      grid-template-rows: repeat(6, 1fr);

      a, img{
        height: 100%;
        width: 100%;
        object-fit: cover;

      }
      img.flecheVoirPlus{
        height: auto;
        width: 60px;
      }
    }


    &:nth-child(odd){
      background-color: $color-black;
      a:first-child{
        grid-column-start: 1;
        grid-column-end: 8;
        grid-row-start: 1;
        grid-row-end: 6;
      }
      a:nth-child(2){
        grid-column-start: 7;
        grid-column-end: 11;
        grid-row-start: 2;
        grid-row-end: 8;
      }
      div.infosProjet{
        padding : 0 10px 0 0;
        grid-column-start: 4;
        grid-column-end: 7;
        grid-row-start: 6;
        grid-row-end: 6;
        text-align: right;
        justify-self: flex-end;

        img{
          transform: rotate(180deg);
        }
      }

    }
    &:nth-child(even){
      div.titreChaqueProjet{
        text-align: right;
      }
      background-color: $color-bg-page;
      a:first-child{
        grid-column-start: 6;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 6;
      }
      a:nth-child(2){
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 2;
        grid-row-end: 8;
      }
      div.infosProjet {
        padding: 0 0 0 10px;
        grid-column-start: 7;
        grid-column-end: 10;
        grid-row-start: 6;
        grid-row-end: 10;
      }
    }

  }
}
@media screen and (min-width: $breakpoint-cellphone) and(max-width: 450px) {
  section.mesProjets{
    div.unProjet{

      div.maGrid{
        margin-top: 1vh;
        height: 55vh;
      }
      &:nth-child(odd){
        background-color: $color-black;
        a:first-child{
          grid-column-start: 1;
          grid-column-end: 12;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          display: none;
          img{
            display: none;
          }
        }
        div.infosProjet{
          padding : 0 10px 0 0;
          grid-column-start: 1;
          grid-column-end: 12;
          grid-row-start: 6;
          grid-row-end: 6;
          text-align: right;
          justify-self: flex-end;

          img{
            transform: rotate(180deg);
          }
          h4{
            font-size: rem(20);
            line-height: rem(30);
          }
        }
      }
      &:nth-child(even){
        div.titreChaqueProjet{
          text-align: left;
        }
        background-color: $color-bg-page;
        a:first-child{
          grid-column-start: 1;
          grid-column-end: 12;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          display: none;
          img{
            display: none;
          }
        }
        div.infosProjet {
          padding: 0 0 0 10px;
          grid-column-start: 1;
          grid-column-end: 10;
          grid-row-start: 6;
          grid-row-end: 10;

          img{
            transform: rotate(180deg);
          }
          h4{
            font-size: rem(20);
            line-height: rem(30);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablette ) and (min-width:451px){
  section.mesProjets{
    div.unProjet{

      div.maGrid {
        margin-top: 2vh;
        display: grid;
        width: 100%;
        height: 60vh;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: repeat(6, 1fr);
      }

      &:nth-child(odd){
        background-color: $color-black;
        a:first-child{
          grid-column-start: 1;
          grid-column-end: 8;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          grid-column-start: 7;
          grid-column-end: 12;
          grid-row-start: 2;
          grid-row-end: 7;
          img{

          }
        }
        div.infosProjet{
          padding : 0 10px 0 0;
          grid-column-start: 2;
          grid-column-end: 7;
          grid-row-start: 6;
          grid-row-end: 7;
          text-align: right;
          justify-self: flex-end;

          img{
            transform: rotate(180deg);
          }
        }
      }
      &:nth-child(even){
        div.titreChaqueProjet{
          text-align: right;
        }
        background-color: $color-bg-page;
        a:first-child{
          grid-column-start: 5;
          grid-column-end: 12;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          grid-column-start: 1;
          grid-column-end: 6;
          grid-row-start: 2;
          grid-row-end: 7;
          img{

          }
        }
        div.infosProjet {
          padding: 0 0 0 10px;
          grid-column-start: 6;
          grid-column-end: 10;
          grid-row-start: 6;
          grid-row-end: 10;

          img{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1250px){
  section.mesProjets {
    div.unProjet {
      height: 100vh;
      padding: 20px 0;



      div.maGrid {
        margin-top: 5vh;
        display: grid;
        width: 100%;
        height: 65vh;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: repeat(6, 1fr);

        a, img{
          height: 100%;
          width: 100%;
          object-fit: cover;

        }
        img.flecheVoirPlus{
          height: auto;
          width: 50px;
        }
      }


      &:nth-child(odd){
        background-color: $color-black;
        a:first-child{
          grid-column-start: 1;
          grid-column-end: 8;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          grid-column-start: 7;
          grid-column-end: 11;
          grid-row-start: 2;
          grid-row-end: 8;
        }
        div.infosProjet{
          padding : 0 10px 0 0;
          grid-column-start: 4;
          grid-column-end: 7;
          grid-row-start: 6;
          grid-row-end: 6;
          text-align: right;
          justify-self: flex-end;

          img{
            transform: rotate(180deg);
          }
          h4{
            font-size: rem(20);
            line-height: rem(30);
          }
        }

      }
      &:nth-child(even){
        margin-right: 15vw;
        div.titreChaqueProjet{
          text-align: right;
        }
        background-color: $color-bg-page;
        a:first-child{
          grid-column-start: 6;
          grid-column-end: 13;
          grid-row-start: 1;
          grid-row-end: 6;
        }
        a:nth-child(2){
          grid-column-start: 3;
          grid-column-end: 7;
          grid-row-start: 2;
          grid-row-end: 8;
        }
        div.infosProjet {
          padding: 0 0 0 10px;
          grid-column-start: 7;
          grid-column-end: 9;
          grid-row-start: 6;
          grid-row-end: 10;
          h4{
            font-size: rem(20);
            line-height: rem(30);
          }
        }
      }

    }
  }
}