// LES STYLES DE BASE DES BALISES <h>

h1,h2,h3,h4,h6{
  margin: 0;
}

h1{
  font-size: rem(300);
  line-height: rem(250);
  font-family: $ff-titre;
  font-weight: $fw-bold;
  margin: 0 rem(10);

  &.monNom{
    font-size: rem(70);
    line-height: rem(90);
    font-weight: $fw-regular;
    margin: 0;
    color: white;
  }
  &.nomPage{
    font-size: rem(250);
    -webkit-text-stroke: 4px $color-red;
    font-weight: $fw-regular;
    display: inline-block;
    color: transparent;
  }
  span{
    font-size: rem(250);
    -webkit-text-stroke: 0 transparent;
    background: -webkit-linear-gradient(white, $color-black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h2{
  font-size: rem(150);
  line-height: rem(125);
  font-family: $ff-titre;
  font-weight: $fw-bold;

  &.numero{
    font-size: rem(300);
    line-height: rem(200);
    -webkit-text-stroke: 3px $color-red;
    font-weight: $fw-regular;
    color: transparent;
    display : inline;
    margin-right: rem(30);
  }
  &.maProfession{
    font-size: rem(30);
    line-height: rem(40);
    font-family: $ff-secondaire;
    font-weight: $fw-bold;
    color: $color-red;
  }
  &.mesProjetsTitre{
    display: inline;
    -webkit-text-stroke: 3px $color-red;
    color: transparent;
    font-weight: $fw-regular;
  }
}

h3{
  font-size: rem(60);
  line-height: rem(45);
  font-family: $ff-titre;
  font-weight: $fw-bold;
}

h4{
  font-size: rem(25);
  line-height: rem(45);
  font-family: $ff-secondaire;
  font-weight: $fw-bold;
  color: $color-red;
}

h5{
  font-size: rem(20);
  text-align: center;
  font-family: $ff-secondaire;
  color: $color-black;
  padding: 10px;
  width: rem(300);
  line-height: 30px;
  transition: all 0.5s ease-in-out;

  box-shadow:
          0px 25px 0px -15px $color-black,
          -25px 0px 0px -15px $color-black,
          0px -25px 0px -15px $color-black,
          25px 0px 0px -15px $color-black,
          0 0 0 2px $color-red;

  &:hover{
    box-shadow:
            0px 30px 0px -25px $color-black,
            -30px 0px 0px -25px $color-black,
            0px -30px 0px -25px $color-black,
            30px 0px 0px -25px $color-black,
            0 0 0 4px $color-red;
  }
}

h6{
  font-size: rem(20);
  color: $color-black;
}


@media screen and (min-width: $breakpoint-cellphone) and(max-width: 450px) {
  h1{
    font-size: rem(300);
    line-height: rem(250);
    font-family: $ff-titre;
    font-weight: $fw-bold;
    margin: 0 rem(10);

    &.monNom{
      font-size: rem(50);
      line-height: rem(60);
      font-weight: $fw-regular;
      margin: 0;
      color: white;
    }
    &.nomPage{
      font-size: rem(70);
      line-height: rem(70);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      display: inline-block;
      color: transparent;
    }
    span{
      font-size: rem(80);
      line-height: rem(80);
      -webkit-text-stroke: 0 transparent;
      background: -webkit-linear-gradient(white, $color-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h2{
    font-size: rem(50);
    line-height: rem(65);
    font-family: $ff-titre;
    font-weight: $fw-bold;

    &.numero{
      font-size: rem(80);
      line-height: rem(80);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      color: transparent;
      display : block;
      margin-right: 0;
    }
    &.maProfession{
      font-size: rem(25);
      line-height: rem(40);
      font-family: $ff-secondaire;
      font-weight: $fw-bold;
      color: $color-red;
    }
    &.mesProjetsTitre{
      display: inline-block;
      -webkit-text-stroke: 2px $color-red;
      color: transparent;
      font-weight: $fw-regular;
    }
  }
}

@media screen and (max-width: $breakpoint-tablette ) and (min-width:451px){
  h1{
    font-size: rem(300);
    line-height: rem(250);
    font-family: $ff-titre;
    font-weight: $fw-bold;
    margin: 0 rem(10);

    &.monNom{
      font-size: rem(50);
      line-height: rem(60);
      font-weight: $fw-regular;
      margin: 0;
      color: white;
    }
    &.nomPage{
      font-size: rem(125);
      line-height: rem(125);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      display: inline;
      color: transparent;
    }
    span{
      font-size: rem(200);
      line-height: rem(200);
      -webkit-text-stroke: 0 transparent;
      background: -webkit-linear-gradient(white, $color-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h2{
    font-size: rem(100);
    line-height: rem(100);
    font-family: $ff-titre;
    font-weight: $fw-bold;

    &.numero{
      font-size: rem(200);
      line-height: rem(175);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      color: transparent;
      display : block;
      margin-right: 0;
    }
    &.maProfession{
      font-size: rem(25);
      line-height: rem(40);
      font-family: $ff-secondaire;
      font-weight: $fw-bold;
      color: $color-red;
    }
    &.mesProjetsTitre{
      display: inline-block;
      -webkit-text-stroke: 2px $color-red;
      color: transparent;
      font-weight: $fw-regular;
    }
  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1250px){
  h1{
    font-size: rem(300);
    line-height: rem(250);
    font-family: $ff-titre;
    font-weight: $fw-bold;
    margin: 0 rem(10);

    &.monNom{
      font-size: rem(50);
      line-height: rem(60);
      font-weight: $fw-regular;
      margin: 0;
      color: white;
    }
    &.nomPage{
      font-size: rem(125);
      line-height: rem(125);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      display: inline;
      color: transparent;
    }
    span{
      font-size: rem(180);
      line-height: rem(180);
      -webkit-text-stroke: 0 transparent;
      background: -webkit-linear-gradient(white, $color-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h2{
    font-size: rem(100);
    line-height: rem(100);
    font-family: $ff-titre;
    font-weight: $fw-bold;

    &.numero{
      font-size: rem(150);
      line-height: rem(150);
      -webkit-text-stroke: 2px $color-red;
      font-weight: $fw-regular;
      color: transparent;
      display : inline-block;
      margin-right: 0;
    }
    &.maProfession{
      font-size: rem(25);
      line-height: rem(40);
      font-family: $ff-secondaire;
      font-weight: $fw-bold;
      color: $color-red;
    }
    &.mesProjetsTitre{
      display: inline-block;
      -webkit-text-stroke: 2px $color-red;
      color: transparent;
      font-weight: $fw-regular;
      font-size: rem(100);
    }
  }
}