// STYLE DE BASE DE TOUS LES LIENS ET BOUTON DU SITE

a{
  &:link{
    text-decoration: none;
    color: inherit;
  }

  &:visited{
    color: inherit;
  }

  &:hover{
    color: inherit;
  }

  &:active{
    color: inherit;
  }

  &:focus{
    color: inherit;
  }
}
div.boutonCenter{
  text-align: center;
}