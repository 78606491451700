// STYLE DE BASE DU WRAPPER

.wrapper{

  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;

}

@media only screen and (min-width: $breakpoint-cellphone) and(max-width: 450px) {
  .wrapper{
    max-width: 350px;
  }
}

@media only screen and (max-width: $breakpoint-tablette ) and (min-width:451px){
  .wrapper{
    max-width: 750px;
  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1250px){
  .wrapper{
    max-width: 1200px;
  }
}