//FICHIER DE CONFIGURATION POUR LES VARIABLES DE COULEUR

// Couleur de base
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #333333;
$black:    #000;

$color-text-normal: #888888;

$color-bg-page: #f4f4f4;

$color-red: #ff5247;
$color-black: #1a1a1a;

//Couleurs des médias sociaux
$color-facebook: #3b5999;
$color-messenger: #0084ff;
$color-twitter: #55acee;
$color-linkedin: #0077B5;
$color-skype: #00AFF0;
$color-dropbox: #007ee5;
$color-wordpress: #21759b;
$color-vimeo: #1ab7ea;
$color-slideshare: #0077b5;
$color-vk: #4c75a3;
$color-tumblr: #34465d;
$color-yahoo: #410093;
$color-googleplus: #dd4b39;
$color-pinterest: #bd081c;
$color-youtube: #cd201f;
$color-stumbleupon: #eb4924;
$color-reddit: #ff5700;
$color-quora: #b92b27;
$color-yelp: #af0606;
$color-weibo: #df2029;
$color-producthunt: #da552f;
$color-hackernews: #ff6600;
$color-soundcloud: #ff3300;
$color-blogger: #f57d00;
$color-whatsapp: #25D366;
$color-wechat: #09b83e;
$color-line: #00c300;
$color-medium: #02b875;
$color-vine: #00b489;
$color-slack: #3aaf85;
$color-instagram: #e4405f;
$color-dribbble: #ea4c89;
$color-flickr: #ff0084;
$color-foursquare: #f94877;
$color-behance: #131418;
$color-snapchat: #FFFC00;
