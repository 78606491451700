//FICHIER DE CONFIGURATION POUR LES VARIABLES DE LARGEUR/HAUTEUR

$max-width: 1500px;


/// Dimension pour les media query

$breakpoint-cellphone: 325px;
$breakpoint-tablette: 1200px;
$breakpoint-laptop: 1480px;
$breakpoint-desktop: 1200px;

