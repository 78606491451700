/*
@mixin font($family, $size: 'inherit', $color: #000, $line-height: 1.2) {
  font-family: $family;
  font-size: $size;
  color: $color;
  line-height: $line-height;
}
*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "DM Sans", sans-serif;
  color: #888888;
  background-color: #f4f4f4;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 1920px;
  cursor: url(../images/cursor.png) 10 10, auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  transition: all 1s ease-in-out;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body div#noScrolling {
  position: fixed;
  overflow-x: hidden;
  max-width: 100vw;
  transition: all 1s ease-in-out;
}

body p {
  margin: 0;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 375px) {
  body {
    max-width: 375px;
  }
  body p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

h1, h2, h3, h4, h6 {
  margin: 0;
}

h1 {
  font-size: 18.75rem;
  line-height: 15.625rem;
  font-family: "Abril Fatface", Helvetica, Arial, serif;
  font-weight: 700;
  margin: 0 0.625rem;
}

h1.monNom {
  font-size: 4.375rem;
  line-height: 5.625rem;
  font-weight: 400;
  margin: 0;
  color: white;
}

h1.nomPage {
  font-size: 15.625rem;
  -webkit-text-stroke: 4px #ff5247;
  font-weight: 400;
  display: inline-block;
  color: transparent;
}

h1 span {
  font-size: 15.625rem;
  -webkit-text-stroke: 0 transparent;
  background: -webkit-linear-gradient(white, #1a1a1a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: 9.375rem;
  line-height: 7.8125rem;
  font-family: "Abril Fatface", Helvetica, Arial, serif;
  font-weight: 700;
}

h2.numero {
  font-size: 18.75rem;
  line-height: 12.5rem;
  -webkit-text-stroke: 3px #ff5247;
  font-weight: 400;
  color: transparent;
  display: inline;
  margin-right: 1.875rem;
}

h2.maProfession {
  font-size: 1.875rem;
  line-height: 2.5rem;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #ff5247;
}

h2.mesProjetsTitre {
  display: inline;
  -webkit-text-stroke: 3px #ff5247;
  color: transparent;
  font-weight: 400;
}

h3 {
  font-size: 3.75rem;
  line-height: 2.8125rem;
  font-family: "Abril Fatface", Helvetica, Arial, serif;
  font-weight: 700;
}

h4 {
  font-size: 1.5625rem;
  line-height: 2.8125rem;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #ff5247;
}

h5 {
  font-size: 1.25rem;
  text-align: center;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  color: #1a1a1a;
  padding: 10px;
  width: 18.75rem;
  line-height: 30px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 25px 0px -15px #1a1a1a, -25px 0px 0px -15px #1a1a1a, 0px -25px 0px -15px #1a1a1a, 25px 0px 0px -15px #1a1a1a, 0 0 0 2px #ff5247;
}

h5:hover {
  box-shadow: 0px 30px 0px -25px #1a1a1a, -30px 0px 0px -25px #1a1a1a, 0px -30px 0px -25px #1a1a1a, 30px 0px 0px -25px #1a1a1a, 0 0 0 4px #ff5247;
}

h6 {
  font-size: 1.25rem;
  color: #1a1a1a;
}

@media screen and (min-width: 325px) and (max-width: 450px) {
  h1 {
    font-size: 18.75rem;
    line-height: 15.625rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
    margin: 0 0.625rem;
  }
  h1.monNom {
    font-size: 3.125rem;
    line-height: 3.75rem;
    font-weight: 400;
    margin: 0;
    color: white;
  }
  h1.nomPage {
    font-size: 4.375rem;
    line-height: 4.375rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    display: inline-block;
    color: transparent;
  }
  h1 span {
    font-size: 5rem;
    line-height: 5rem;
    -webkit-text-stroke: 0 transparent;
    background: -webkit-linear-gradient(white, #1a1a1a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    font-size: 3.125rem;
    line-height: 4.0625rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
  }
  h2.numero {
    font-size: 5rem;
    line-height: 5rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    color: transparent;
    display: block;
    margin-right: 0;
  }
  h2.maProfession {
    font-size: 1.5625rem;
    line-height: 2.5rem;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #ff5247;
  }
  h2.mesProjetsTitre {
    display: inline-block;
    -webkit-text-stroke: 2px #ff5247;
    color: transparent;
    font-weight: 400;
  }
}

@media screen and (max-width: 1200px) and (min-width: 451px) {
  h1 {
    font-size: 18.75rem;
    line-height: 15.625rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
    margin: 0 0.625rem;
  }
  h1.monNom {
    font-size: 3.125rem;
    line-height: 3.75rem;
    font-weight: 400;
    margin: 0;
    color: white;
  }
  h1.nomPage {
    font-size: 7.8125rem;
    line-height: 7.8125rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    display: inline;
    color: transparent;
  }
  h1 span {
    font-size: 12.5rem;
    line-height: 12.5rem;
    -webkit-text-stroke: 0 transparent;
    background: -webkit-linear-gradient(white, #1a1a1a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    font-size: 6.25rem;
    line-height: 6.25rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
  }
  h2.numero {
    font-size: 12.5rem;
    line-height: 10.9375rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    color: transparent;
    display: block;
    margin-right: 0;
  }
  h2.maProfession {
    font-size: 1.5625rem;
    line-height: 2.5rem;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #ff5247;
  }
  h2.mesProjetsTitre {
    display: inline-block;
    -webkit-text-stroke: 2px #ff5247;
    color: transparent;
    font-weight: 400;
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1250px) {
  h1 {
    font-size: 18.75rem;
    line-height: 15.625rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
    margin: 0 0.625rem;
  }
  h1.monNom {
    font-size: 3.125rem;
    line-height: 3.75rem;
    font-weight: 400;
    margin: 0;
    color: white;
  }
  h1.nomPage {
    font-size: 7.8125rem;
    line-height: 7.8125rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    display: inline;
    color: transparent;
  }
  h1 span {
    font-size: 11.25rem;
    line-height: 11.25rem;
    -webkit-text-stroke: 0 transparent;
    background: -webkit-linear-gradient(white, #1a1a1a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    font-size: 6.25rem;
    line-height: 6.25rem;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    font-weight: 700;
  }
  h2.numero {
    font-size: 9.375rem;
    line-height: 9.375rem;
    -webkit-text-stroke: 2px #ff5247;
    font-weight: 400;
    color: transparent;
    display: inline-block;
    margin-right: 0;
  }
  h2.maProfession {
    font-size: 1.5625rem;
    line-height: 2.5rem;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #ff5247;
  }
  h2.mesProjetsTitre {
    display: inline-block;
    -webkit-text-stroke: 2px #ff5247;
    color: transparent;
    font-weight: 400;
    font-size: 6.25rem;
  }
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}

a:focus {
  color: inherit;
}

div.boutonCenter {
  text-align: center;
}

.wrapper {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 325px) and (max-width: 450px) {
  .wrapper {
    max-width: 350px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 451px) {
  .wrapper {
    max-width: 750px;
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1250px) {
  .wrapper {
    max-width: 1200px;
  }
}

/*
@mixin font($family, $size: 'inherit', $color: #000, $line-height: 1.2) {
  font-family: $family;
  font-size: $size;
  color: $color;
  line-height: $line-height;
}
*/
header img#openMenu {
  position: fixed;
  top: 7vw;
  right: 7vw;
  z-index: 1;
  transform: translateY(0);
  cursor: pointer;
  transition: transform 1s ease-in-out;
}

header img#closeMenu {
  position: absolute;
  top: 7vw;
  right: 7vw;
  cursor: pointer;
}

header .overlay-content {
  position: absolute;
  top: 0;
  left: 10%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

header .overlay {
  position: absolute;
  height: 100vh;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  background-color: #f4f4f4;
  transition: width 0.5s;
  overflow: hidden;
}

header .overlay ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  margin: 0;
}

header .overlay ul li {
  font-size: 12.5rem;
  color: #ced4da;
  transition: all 0.3s ease-in;
  font-family: "Abril Fatface", Helvetica, Arial, serif;
  white-space: nowrap;
  cursor: pointer;
}

header .overlay ul li:hover {
  color: #ff5247;
}

header ul.menuSide {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  top: 40%;
  height: 30vh;
  right: 5%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

header ul.menuSide li {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.25rem;
  text-align: right;
  justify-content: flex-end;
}

header ul.menuSide li span {
  order: 2;
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 3px solid #ff5247;
  transform: rotate(45deg);
  margin-left: 0.625rem;
  justify-self: flex-end;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}

header ul.menuSide li span:hover {
  background-color: #ff5247;
}

header ul.menuSide li span:hover + p {
  opacity: 1;
}

header ul.menuSide li p {
  opacity: 0;
  margin: 0;
  padding: 0.3125rem 0.625rem;
  background-color: #1a1a1a;
  transition: all 0.2s ease-in-out;
}

.bodyOverflow {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 450px) and (min-width: 325px) {
  header img#openMenu {
    position: fixed;
    top: 7vw;
    right: 7vw;
    z-index: 1;
    transform: translateY(0);
    cursor: pointer;
    transition: transform 1s ease-in-out;
  }
  header img#closeMenu {
    position: absolute;
    top: 7vw;
    right: 7vw;
    cursor: pointer;
  }
  header .overlay-content {
    position: absolute;
    top: 0;
    left: 5%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  header .overlay {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    background-color: #f4f4f4;
    transition: width 0.5s;
    overflow: hidden;
  }
  header .overlay ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  header .overlay ul li {
    font-size: 5rem;
    color: #ced4da;
    transition: all 0.3s ease-in;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    white-space: nowrap;
  }
  header .overlay ul li:hover {
    color: #ff5247;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 451px) {
  header img#openMenu {
    position: fixed;
    top: 7vw;
    right: 7vw;
    z-index: 1;
    transform: translateY(0);
    cursor: pointer;
    transition: transform 1s ease-in-out;
  }
  header img#closeMenu {
    position: absolute;
    top: 7vw;
    right: 7vw;
    cursor: pointer;
  }
  header .overlay-content {
    position: absolute;
    top: 0;
    left: 5%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  header .overlay {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    background-color: #f4f4f4;
    transition: width 0.5s;
    overflow: hidden;
  }
  header .overlay ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  header .overlay ul li {
    font-size: 6.25rem;
    color: #ced4da;
    transition: all 0.3s ease-in;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    white-space: nowrap;
  }
  header .overlay ul li:hover {
    color: #ff5247;
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1250px) {
  header img#openMenu {
    position: fixed;
    top: 7vw;
    right: 7vw;
    z-index: 1;
    transform: translateY(0);
    cursor: pointer;
    transition: transform 1s ease-in-out;
  }
  header img#closeMenu {
    position: absolute;
    top: 7vw;
    right: 7vw;
    cursor: pointer;
  }
  header .overlay-content {
    position: absolute;
    top: 0;
    left: 5%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  header .overlay {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    background-color: #f4f4f4;
    transition: width 0.5s;
    overflow: hidden;
  }
  header .overlay ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  header .overlay ul li {
    font-size: 8.125rem;
    color: #ced4da;
    transition: all 0.3s ease-in;
    font-family: "Abril Fatface", Helvetica, Arial, serif;
    white-space: nowrap;
  }
}

section.hero {
  position: relative;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background: url("../images/hero.gif") no-repeat;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section.hero h1 {
  text-align: center;
}

section.hero div.downloadCV h5 {
  color: #f4f4f4;
}

section.hero div.mesProjetsHero {
  position: absolute;
  bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section.hero div.mesProjetsHero img {
  margin-top: 1.875rem;
  animation: bobbing 3s ease-in-out infinite;
}

@keyframes bobbing {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

section.hero #flecheHero {
  width: 1.9375rem;
  height: 1.1875rem;
}

@media screen and (max-width: 325px) {
  section.hero {
    position: relative;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    background: url("../images/hero.gif") no-repeat;
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

section.mesProjets div.unProjet {
  height: 100vh;
  padding: 20px 0;
}

section.mesProjets div.unProjet div.maGrid {
  margin-top: 5vh;
  display: grid;
  width: 100%;
  height: 65vh;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(6, 1fr);
}

section.mesProjets div.unProjet div.maGrid a, section.mesProjets div.unProjet div.maGrid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section.mesProjets div.unProjet div.maGrid img.flecheVoirPlus {
  height: auto;
  width: 60px;
}

section.mesProjets div.unProjet:nth-child(odd) {
  background-color: #1a1a1a;
}

section.mesProjets div.unProjet:nth-child(odd) a:first-child {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 6;
}

section.mesProjets div.unProjet:nth-child(odd) a:nth-child(2) {
  grid-column-start: 7;
  grid-column-end: 11;
  grid-row-start: 2;
  grid-row-end: 8;
}

section.mesProjets div.unProjet:nth-child(odd) div.infosProjet {
  padding: 0 10px 0 0;
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 6;
  grid-row-end: 6;
  text-align: right;
  justify-self: flex-end;
}

section.mesProjets div.unProjet:nth-child(odd) div.infosProjet img {
  transform: rotate(180deg);
}

section.mesProjets div.unProjet:nth-child(even) {
  background-color: #f4f4f4;
}

section.mesProjets div.unProjet:nth-child(even) div.titreChaqueProjet {
  text-align: right;
}

section.mesProjets div.unProjet:nth-child(even) a:first-child {
  grid-column-start: 6;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 6;
}

section.mesProjets div.unProjet:nth-child(even) a:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 8;
}

section.mesProjets div.unProjet:nth-child(even) div.infosProjet {
  padding: 0 0 0 10px;
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 6;
  grid-row-end: 10;
}

@media screen and (min-width: 325px) and (max-width: 450px) {
  section.mesProjets div.unProjet div.maGrid {
    margin-top: 1vh;
    height: 55vh;
  }
  section.mesProjets div.unProjet:nth-child(odd) {
    background-color: #1a1a1a;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:first-child {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:nth-child(2) {
    display: none;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:nth-child(2) img {
    display: none;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet {
    padding: 0 10px 0 0;
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 6;
    grid-row-end: 6;
    text-align: right;
    justify-self: flex-end;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet img {
    transform: rotate(180deg);
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  section.mesProjets div.unProjet:nth-child(even) {
    background-color: #f4f4f4;
  }
  section.mesProjets div.unProjet:nth-child(even) div.titreChaqueProjet {
    text-align: left;
  }
  section.mesProjets div.unProjet:nth-child(even) a:first-child {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(even) a:nth-child(2) {
    display: none;
  }
  section.mesProjets div.unProjet:nth-child(even) a:nth-child(2) img {
    display: none;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet {
    padding: 0 0 0 10px;
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 10;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet img {
    transform: rotate(180deg);
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 451px) {
  section.mesProjets div.unProjet div.maGrid {
    margin-top: 2vh;
    display: grid;
    width: 100%;
    height: 60vh;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
  section.mesProjets div.unProjet:nth-child(odd) {
    background-color: #1a1a1a;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:first-child {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 2;
    grid-row-end: 7;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet {
    padding: 0 10px 0 0;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 6;
    grid-row-end: 7;
    text-align: right;
    justify-self: flex-end;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet img {
    transform: rotate(180deg);
  }
  section.mesProjets div.unProjet:nth-child(even) {
    background-color: #f4f4f4;
  }
  section.mesProjets div.unProjet:nth-child(even) div.titreChaqueProjet {
    text-align: right;
  }
  section.mesProjets div.unProjet:nth-child(even) a:first-child {
    grid-column-start: 5;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(even) a:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 7;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet {
    padding: 0 0 0 10px;
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 10;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet img {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1250px) {
  section.mesProjets div.unProjet {
    height: 100vh;
    padding: 20px 0;
  }
  section.mesProjets div.unProjet div.maGrid {
    margin-top: 5vh;
    display: grid;
    width: 100%;
    height: 65vh;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
  section.mesProjets div.unProjet div.maGrid a, section.mesProjets div.unProjet div.maGrid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  section.mesProjets div.unProjet div.maGrid img.flecheVoirPlus {
    height: auto;
    width: 50px;
  }
  section.mesProjets div.unProjet:nth-child(odd) {
    background-color: #1a1a1a;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:first-child {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(odd) a:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 8;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet {
    padding: 0 10px 0 0;
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 6;
    grid-row-end: 6;
    text-align: right;
    justify-self: flex-end;
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet img {
    transform: rotate(180deg);
  }
  section.mesProjets div.unProjet:nth-child(odd) div.infosProjet h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  section.mesProjets div.unProjet:nth-child(even) {
    margin-right: 15vw;
    background-color: #f4f4f4;
  }
  section.mesProjets div.unProjet:nth-child(even) div.titreChaqueProjet {
    text-align: right;
  }
  section.mesProjets div.unProjet:nth-child(even) a:first-child {
    grid-column-start: 6;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  section.mesProjets div.unProjet:nth-child(even) a:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 8;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet {
    padding: 0 0 0 10px;
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 10;
  }
  section.mesProjets div.unProjet:nth-child(even) div.infosProjet h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

section.contactAccueil {
  background-color: #1a1a1a;
  height: 100vh;
}

section.contactAccueil div.wrapper {
  display: flex;
  padding: 3.125rem 0 6.25rem 0;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

section.contactAccueil div.wrapper h1 {
  display: inline-block;
  text-align: center;
  color: #ff5247;
  padding: 0;
  margin: 0;
  font-size: 12.5rem;
  line-height: 10.625rem;
  font-weight: 400;
}

section.contactAccueil div.wrapper h5 {
  color: white;
}

section.contactAccueil div.wrapper p {
  margin-top: 3vh;
  width: 50%;
  align-self: flex-start;
  color: white;
}

section.contactAccueil div.wrapper ul {
  display: flex;
  align-self: flex-start;
  padding: 0;
  list-style-type: none;
}

section.contactAccueil div.wrapper ul li {
  margin-right: 6.25rem;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.25rem;
}

section.contactAccueil div.wrapper ul img {
  margin-right: 1.25rem;
  display: inline;
}

section.contactAccueil div.wrapper ul.mediaSociauxContact {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 50%;
}

section.contactAccueil div.wrapper ul.mediaSociauxContact li {
  margin-right: 0;
  font-size: 3.75rem;
  color: #f4f4f4;
  transition: all, 0.2s ease-in-out;
}

section.contactAccueil div.wrapper ul.mediaSociauxContact li:hover {
  color: #ff5247;
  transform: rotate(30deg);
}

@media only screen and (min-width: 325px) and (max-width: 450px) {
  section.contactAccueil {
    background-color: #1a1a1a;
    height: 100vh;
  }
  section.contactAccueil div.wrapper {
    display: flex;
    padding: 3.125rem 0 6.25rem 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  section.contactAccueil div.wrapper h1 {
    display: inline;
    text-align: center;
    color: #ff5247;
    padding: 0;
    margin: 0;
    font-size: 5.625rem;
    line-height: 3.75rem;
    font-weight: 400;
  }
  section.contactAccueil div.wrapper h2 {
    text-align: center;
    margin: auto;
    display: inline-block;
  }
  section.contactAccueil div.wrapper h5 {
    color: white;
    margin-top: 0.3125rem;
  }
  section.contactAccueil div.wrapper p {
    text-align: center;
    width: 100%;
  }
  section.contactAccueil div.wrapper ul {
    display: block;
    align-self: center;
    padding: 0;
    list-style-type: none;
    margin-bottom: 0.3125rem;
  }
  section.contactAccueil div.wrapper ul li {
    margin: 0.625rem 0 0;
    display: block;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 1.25rem;
  }
  section.contactAccueil div.wrapper ul img {
    margin-right: 1.25rem;
    display: inline;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact {
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 90%;
    margin: 10% 0;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact li {
    margin-right: 0;
    font-size: 2.5rem;
    color: #f4f4f4;
    transition: all, 0.2s ease-in-out;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact li:hover {
    color: #ff5247;
    transform: rotate(30deg);
  }
}

@media only screen and (min-width: 451px) and (max-width: 1200px) {
  section.contactAccueil {
    background-color: #1a1a1a;
    height: 100vh;
  }
  section.contactAccueil div.wrapper {
    display: flex;
    padding: 3.125rem 0 6.25rem 0;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  section.contactAccueil div.wrapper h2 {
    display: block;
  }
  section.contactAccueil div.wrapper h1 {
    display: block;
    text-align: left;
    color: #ff5247;
    padding: 0;
    margin: 0;
    font-size: 10.9375rem;
    line-height: 7.8125rem;
    font-weight: 400;
  }
  section.contactAccueil div.wrapper h5 {
    color: white;
  }
  section.contactAccueil div.wrapper p {
    margin-top: 3vh;
    width: 100%;
    text-align: center;
    align-self: flex-start;
    color: white;
  }
  section.contactAccueil div.wrapper ul {
    display: flex;
    align-self: center;
    padding: 0;
    list-style-type: none;
  }
  section.contactAccueil div.wrapper ul li {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
    display: flex;
    align-items: center;
    color: white;
    font-size: 1.25rem;
  }
  section.contactAccueil div.wrapper ul img {
    margin-right: 0.625rem;
    display: inline;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact {
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 80%;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact li {
    margin: 0;
    font-size: 3.75rem;
    color: #f4f4f4;
    transition: all, 0.2s ease-in-out;
  }
  section.contactAccueil div.wrapper ul.mediaSociauxContact li:hover {
    color: #ff5247;
    transform: rotate(30deg);
  }
}

div#contenuFenetreModale {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

div#contenuFenetreModale img {
  max-width: 80vw;
}

div#fenetreModale {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

div.toute {
  height: 100vh;
}

div.testitoo {
  display: flex;
  align-items: flex-start;
}

span.rotate90numero {
  margin-top: 100px;
  transform: rotate(270deg);
  display: inline-block;
}

div.pageUnprojet {
  margin-top: 60px;
  height: 100vh;
}

div.pageUnprojet div.specificationsProjet {
  display: flex;
  justify-content: space-between;
  border-left: 2px solid #1a1a1a;
  padding-left: 15px;
}

div.pageUnprojet div.specificationsProjet div.specificationsGauche {
  display: flex;
}

div.pageUnprojet div.specificationsProjet div.specificationsGauche div {
  width: 15vw;
}

div.pageUnprojet div.scrollOuNextPrevProjet {
  margin-top: 4vh;
  display: flex;
  justify-content: space-between;
}

div.pageUnprojet div.scrollOuNextPrevProjet div {
  display: flex;
  flex-direction: column;
}

div.pageUnprojet div.scrollOuNextPrevProjet div h6 {
  font-weight: 300;
}

div.pageUnprojet div.scrollOuNextPrevProjet div img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transform: rotate(0deg);
  align-self: flex-start;
  margin-top: 0.625rem;
}

div.pageUnprojet div.scrollOuNextPrevProjet div img.flecheFlip {
  transform: rotate(180deg);
  align-self: flex-end;
}

div.pageUnprojet div.scrollOuNextPrevProjet span.iconScrollAnimation {
  display: inline-block;
  position: relative;
  height: 80px;
  border-radius: 50%/33%;
  border: solid #ff5247 5px;
  width: 50px;
}

div.pageUnprojet div.scrollOuNextPrevProjet span.iconScrollAnimation:after {
  content: "";
  background-color: black;
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 50px;
  left: 15px;
  animation: scrollingAnim 1s infinite;
}

@keyframes scrollingAnim {
  from {
    top: 50px;
    opacity: 1;
  }
  to {
    top: 10px;
    opacity: 0;
  }
}

section.sectionImagesProjet {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  background-color: #1a1a1a;
  /* &:hover ~ div.sectionImagesProjet img, ~ img{
     opacity: 0;
   }*/
}

section.sectionImagesProjet:hover * {
  opacity: 0;
}

section.sectionImagesProjet img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.2;
  cursor: zoom-in;
}

section.sectionImagesProjet img:hover {
  opacity: 1;
}

section.sectionImagesProjet img.one {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 6;
  transition: all 1s linear;
}

section.sectionImagesProjet img.two {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;
  transition: all 0.8s linear;
}

section.sectionImagesProjet img.three {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 6;
  grid-row-end: 11;
  transition: all 1.2s linear;
}

section.sectionImagesProjet img.four {
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 6;
  grid-row-end: 11;
  transition: all 1.1s linear;
}

section.sectionImagesProjet img.five {
  grid-column-start: 8;
  grid-column-end: 11;
  grid-row-start: 6;
  grid-row-end: 11;
  transition: all 1.3s linear;
}

section.sectionImagesProjet img.six {
  grid-column-start: 9;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 6;
  transition: all 1s linear;
}

@media only screen and (min-width: 325px) and (max-width: 450px) {
  div.testitoo {
    display: block;
    align-items: flex-start;
  }
  span.rotate90numero {
    margin-top: 0;
    transform: rotate(0deg);
    display: block;
  }
  div.pageUnprojet {
    margin-top: 1.875rem;
    height: 100vh;
  }
  div.pageUnprojet p {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
  div.pageUnprojet div.specificationsProjet {
    display: block;
    border-left: 2px solid #1a1a1a;
    padding-left: 15px;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche {
    display: flex;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche div {
    width: 45%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
  section.sectionImagesProjet {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: #1a1a1a;
    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
  section.sectionImagesProjet:hover * {
    opacity: 0;
  }
  section.sectionImagesProjet img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.2;
  }
  section.sectionImagesProjet img:hover {
    opacity: 1;
  }
  section.sectionImagesProjet img.one {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    transition: all 1s linear;
  }
  section.sectionImagesProjet img.two {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
    transition: all 0.8s linear;
  }
  section.sectionImagesProjet img.three {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    transition: all 1.2s linear;
  }
  section.sectionImagesProjet img.four {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 5;
    transition: all 1.1s linear;
  }
  section.sectionImagesProjet img.five {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
    transition: all 1.3s linear;
  }
  section.sectionImagesProjet img.six {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 7;
    transition: all 1s linear;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 451px) {
  div.testitoo {
    display: block;
    align-items: flex-start;
  }
  span.rotate90numero {
    margin-top: 0;
    transform: rotate(0deg);
    display: block;
  }
  div.pageUnprojet {
    margin-top: 3.75rem;
    height: 100vh;
  }
  div.pageUnprojet div.specificationsProjet {
    display: block;
    border-left: 2px solid #1a1a1a;
    padding-left: 15px;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche {
    display: flex;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche div {
    width: 45%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
  section.sectionImagesProjet {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: #1a1a1a;
    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
  section.sectionImagesProjet:hover * {
    opacity: 0;
  }
  section.sectionImagesProjet img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.2;
  }
  section.sectionImagesProjet img:hover {
    opacity: 1;
  }
  section.sectionImagesProjet img.one {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    transition: all 1s linear;
  }
  section.sectionImagesProjet img.two {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
    transition: all 0.8s linear;
  }
  section.sectionImagesProjet img.three {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    transition: all 1.2s linear;
  }
  section.sectionImagesProjet img.four {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 5;
    transition: all 1.1s linear;
  }
  section.sectionImagesProjet img.five {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
    transition: all 1.3s linear;
  }
  section.sectionImagesProjet img.six {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 7;
    transition: all 1s linear;
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1200px) {
  div.toute {
    height: 100vh;
  }
  div.testitoo {
    display: flex;
    align-items: flex-start;
  }
  span.rotate90numero {
    margin-top: 100px;
    transform: rotate(270deg);
    font-size: 10rem;
    display: inline-block;
  }
  div.pageUnprojet {
    margin-top: 60px;
    height: 100vh;
  }
  div.pageUnprojet div.specificationsProjet {
    display: flex;
    justify-content: space-between;
    border-left: 2px solid #1a1a1a;
    padding-left: 15px;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche {
    display: flex;
  }
  div.pageUnprojet div.specificationsProjet div.specificationsGauche div {
    width: 15vw;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet {
    margin-top: 4vh;
    display: flex;
    justify-content: space-between;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet div {
    display: flex;
    flex-direction: column;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet div h6 {
    font-weight: 300;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet div img {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transform: rotate(0deg);
    align-self: flex-start;
    margin-top: 0.625rem;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet div img.flecheFlip {
    transform: rotate(180deg);
    align-self: flex-end;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet span.iconScrollAnimation {
    display: inline-block;
    position: relative;
    height: 80px;
    border-radius: 50%/33%;
    border: solid #ff5247 5px;
    width: 50px;
  }
  div.pageUnprojet div.scrollOuNextPrevProjet span.iconScrollAnimation:after {
    content: "";
    background-color: black;
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 50px;
    left: 15px;
    animation: scrollingAnim 1s infinite;
  }
  @keyframes scrollingAnim {
    from {
      top: 50px;
      opacity: 1;
    }
    to {
      top: 10px;
      opacity: 0;
    }
  }
  section.sectionImagesProjet {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    background-color: #1a1a1a;
    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
  section.sectionImagesProjet:hover * {
    opacity: 0;
  }
  section.sectionImagesProjet img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.2;
  }
  section.sectionImagesProjet img:hover {
    opacity: 1;
  }
  section.sectionImagesProjet img.one {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 1s linear;
  }
  section.sectionImagesProjet img.two {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 0.8s linear;
  }
  section.sectionImagesProjet img.three {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.2s linear;
  }
  section.sectionImagesProjet img.four {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.1s linear;
  }
  section.sectionImagesProjet img.five {
    grid-column-start: 8;
    grid-column-end: 11;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.3s linear;
  }
  section.sectionImagesProjet img.six {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 1s linear;
  }
}

div#noScrolling.aproposNoScrolling {
  position: fixed;
  width: 100vw;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je {
  height: 100vh;
  text-align: center;
  margin: 0 auto;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je h1 {
  height: 10vh;
  color: #1a1a1a;
  margin-bottom: 10vh;
  font-size: 7.5rem;
  line-height: 15rem;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites {
  overflow: hidden;
  height: 80vh;
  margin: 10vh 0;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos {
  height: 200vh;
  padding: 10vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.6s ease-in-out;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos h2 {
  display: block;
  font-size: 3.75rem;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
  color: transparent;
  text-shadow: 0 0 3px #dee2e6;
  transition: all 0.6s ease-in-out;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2 {
  font-size: 8.75rem;
  color: #ff5247;
}

div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2little {
  font-size: 5.625rem;
  color: #ced4da;
}

div#noScrolling.aproposNoScrolling section.texteAPropos {
  background-color: #1a1a1a;
  height: 100vh;
  overflow: hidden;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis {
  display: flex;
  height: 100%;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis img.maPhotoAPropos {
  width: 50%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos {
  margin: 2vh 5vh;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2, div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
  color: #ff5247;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
  padding: 1.875rem 0;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2 {
  font-size: 7.5rem;
  margin-bottom: 1.25rem;
  color: transparent;
  -webkit-text-stroke: 1px #f4f4f4;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos p {
  margin: 1.25rem 0;
  color: white;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h5 {
  color: white;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels {
  display: flex;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div {
  margin-top: 2.5rem;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels h4 {
  font-size: 2.5rem;
  margin-top: 0.625rem;
  margin-bottom: 0.9375rem;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences {
  margin-right: 7.5rem;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences img {
  height: 50px;
  margin-right: 0.5rem;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul li {
  font-size: 1.25rem;
  color: white;
}

@media only screen and (min-width: 325px) and (max-width: 450px) {
  div#noScrolling.aproposNoScrolling section.quiSuis-je h1 {
    height: 10vh;
    color: #1a1a1a;
    margin-bottom: 0;
    padding-top: 3.75rem;
    font-size: 3.75rem;
    line-height: 7.5rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos h2 {
    display: block;
    font-size: 1.875rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 3px #dee2e6;
    transition: all 0.6s ease-in-out;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2 {
    font-size: 3.75rem;
    color: #ff5247;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2little {
    font-size: 2.8125rem;
    color: #ced4da;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos {
    background-color: rgba(26, 26, 26, 0.6);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis {
    display: flex;
    height: 100%;
    position: relative;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis img.maPhotoAPropos {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos {
    margin: 2vh 5vh;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2, div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    color: #ff5247;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    padding: 0;
    font-size: 2.1875rem;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    width: 80%;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2 {
    font-size: 2.5rem;
    margin-bottom: 0;
    color: transparent;
    -webkit-text-stroke: 1px #f4f4f4;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos p {
    margin: 1.25rem 0;
    color: white;
    width: 80vw;
    font-size: 0.9375rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h5 {
    color: white;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels {
    display: flex;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div {
    margin-top: 0;
    margin-bottom: 0.625rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels h4 {
    font-size: 1.5625rem;
    margin-top: 0;
    margin-bottom: 0.9375rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences {
    margin-right: 1.25rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences img {
    height: 30px;
    margin-right: 0.5rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul li {
    font-size: 1rem;
    color: white;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences h5 {
    display: none;
    box-shadow: none;
    border: solid #ff5247 3px;
    position: absolute;
    bottom: 10px;
    left: 10%;
  }
}

@media only screen and (min-width: 451px) and (max-width: 1200px) {
  div#noScrolling.aproposNoScrolling section.quiSuis-je h1 {
    margin-bottom: 5vh;
    font-size: 6.25rem;
    line-height: 12.5rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos h2 {
    font-size: 3.125rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2 {
    font-size: 6.25rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2little {
    font-size: 4.6875rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos {
    background-color: #1a1a1a;
    height: 100vh;
    overflow: hidden;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis img.maPhotoAPropos {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.6;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos {
    margin: 2vh 2vh;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2, div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    padding: 0;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    font-size: 3.125rem;
    line-height: 4.375rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2 {
    font-size: 5rem;
    margin-bottom: 0rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos p {
    width: 50vw;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels {
    display: flex;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div {
    margin-top: 0.625rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels h4 {
    font-size: 1.875rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences {
    margin-right: 1.25rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences img {
    height: 40px;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences h5 {
    position: absolute;
    bottom: 0;
    left: 50%;
  }
}

@media only screen and (max-width: 1480px) and (min-width: 1250px) {
  div#noScrolling.aproposNoScrolling section.quiSuis-je h1 {
    height: 15vh;
    color: #1a1a1a;
    margin-bottom: 10vh;
    font-size: 7.5rem;
    line-height: 15rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites {
    overflow: hidden;
    height: 80vh;
    margin: 10vh 0;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos h2 {
    display: block;
    font-size: 3.125rem;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2 {
    font-size: 7.5rem;
    color: #ff5247;
  }
  div#noScrolling.aproposNoScrolling section.quiSuis-je div.overflowQualites div.adjectifsApropos .activeH2little {
    font-size: 5rem;
    color: #ced4da;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos {
    background-color: #1a1a1a;
    height: 100vh;
    overflow: hidden;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis {
    display: flex;
    height: 100%;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis img.maPhotoAPropos {
    width: 50%;
    height: 100%;
    object-fit: cover;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos {
    margin: 2vh 5vh;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2, div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    color: #ff5247;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h3 {
    padding: 0rem 0;
    font-size: 2.5rem;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h2 {
    font-size: 6.25rem;
    margin-bottom: 1.25rem;
    color: transparent;
    -webkit-text-stroke: 1px #f4f4f4;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos p {
    margin: 0.625rem 0;
    color: white;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos h5 {
    color: white;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels {
    display: flex;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div {
    margin-top: 0;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels h4 {
    font-size: 1.875rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.625rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences {
    margin-right: 1.875rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colGaucheCompetences img {
    height: 45px;
    margin-right: 0.5rem;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  div#noScrolling.aproposNoScrolling section.texteAPropos div.heyJeSuis div.informationsAPropos div.mesCompetencesEtLogiciels div.colDroiteCompetences ul li {
    font-size: 1.25rem;
    color: white;
  }
}

.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-hidden {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
