div#contenuFenetreModale{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img{
    max-width: 80vw;
  }
}
div#fenetreModale{
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background-color: rgba(black,0.85);
  cursor: pointer;
}

div.toute{
  height:100vh;
}
div.testitoo{
  display: flex;
  align-items: flex-start;
}
span.rotate90numero{
  margin-top: 100px;
  transform: rotate(270deg);
  display: inline-block;
}
div.pageUnprojet{
  margin-top: 60px;
  height: 100vh;

  div.specificationsProjet{
    display: flex;
    justify-content: space-between;
    border-left: 2px solid $color-black;
    padding-left: 15px;

    div.specificationsGauche{
      display: flex;
      div{
        width: 15vw;
      }
    }
  }
  div.scrollOuNextPrevProjet{
    margin-top: 4vh;
    display: flex;
    justify-content: space-between;
    div{
      display: flex;
      flex-direction: column;
        h6{
          font-weight: $fw-light;
        }
        img{
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          transform: rotate(0deg);
          align-self: flex-start;
          margin-top: rem(10);
        }

      img.flecheFlip{
        transform: rotate(180deg);
        align-self: flex-end;
      }

    }
    span.iconScrollAnimation{
      display: inline-block;
      position: relative;
      height: 80px;
      border-radius: 50%/33%;
      border: solid $color-red 5px;
      width: 50px;

      &:after{
        content:"";
        background-color: black;
        position: absolute;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 50px;
        left: 15px;
        animation: scrollingAnim 1s infinite;
      }

      @keyframes scrollingAnim {
        from {top: 50px;opacity: 1;}
        to {top: 10px;opacity:0}
      }
    }
  }
}

section.sectionImagesProjet{
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  background-color: $color-black;

  &:hover *{
    opacity: 0;
  }

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity : 0.2;
    cursor : zoom-in;
    &:hover {
      opacity: 1;
    }
  }

  img.one{
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 1s linear;
  }
  img.two{
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 0.8s linear;
  }
  img.three{
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.2s linear;
  }
  img.four{
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.1s linear;
  }
  img.five{
    grid-column-start: 8;
    grid-column-end: 11;
    grid-row-start: 6;
    grid-row-end: 11;
    transition: all 1.3s linear;
  }
  img.six{
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 6;
    transition: all 1s linear;
  }


  /* &:hover ~ div.sectionImagesProjet img, ~ img{
     opacity: 0;
   }*/
}

@media only screen and (min-width: $breakpoint-cellphone) and(max-width: 450px) {
  div.testitoo{
    display: block;
    align-items: flex-start;
  }
  span.rotate90numero{
    margin-top: 0;
    transform: rotate(0deg);
    display: block;
  }
  div.pageUnprojet{
    margin-top: rem(30);
    height: 100vh;
    p{
      font-size: rem(15);
      line-height: rem(20);
    }

    div.specificationsProjet{
      display: block;
      border-left: 2px solid $color-black;
      padding-left: 15px;

      div.specificationsGauche{
        display: flex;
        div{
          width: 45%;
          margin-right: 0;
          margin-bottom: rem(20);
        }
      }
    }
  }
  section.sectionImagesProjet{
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $color-black;

    &:hover *{
      opacity: 0;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity : 0.2;
      &:hover {
        opacity: 1;
      }
    }

    img.one{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      transition: all 1s linear;
    }
    img.two{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
      transition: all 0.8s linear;
    }
    img.three{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 5;
      transition: all 1.2s linear;
    }
    img.four{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 5;
      transition: all 1.1s linear;
    }
    img.five{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 5;
      grid-row-end: 7;
      transition: all 1.3s linear;
    }
    img.six{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 5;
      grid-row-end: 7;
      transition: all 1s linear;
    }


    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
}

@media only screen and (max-width: $breakpoint-tablette ) and (min-width:451px){
  div.testitoo{
    display: block;
    align-items: flex-start;
  }
  span.rotate90numero{
    margin-top: 0;
    transform: rotate(0deg);
    display: block;
  }
  div.pageUnprojet{
    margin-top: rem(60);
    height: 100vh;

    div.specificationsProjet{
      display: block;
      border-left: 2px solid $color-black;
      padding-left: 15px;

      div.specificationsGauche{
        display: flex;
        div{
          width: 45%;
          margin-right: 0;
          margin-bottom: rem(20);
        }
      }
    }
  }
  section.sectionImagesProjet{
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $color-black;

    &:hover *{
      opacity: 0;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity : 0.2;
      &:hover {
        opacity: 1;
      }
    }

    img.one{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      transition: all 1s linear;
    }
    img.two{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
      transition: all 0.8s linear;
    }
    img.three{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 5;
      transition: all 1.2s linear;
    }
    img.four{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 5;
      transition: all 1.1s linear;
    }
    img.five{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 5;
      grid-row-end: 7;
      transition: all 1.3s linear;
    }
    img.six{
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 5;
      grid-row-end: 7;
      transition: all 1s linear;
    }


    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1200px){
  div.toute{
    height:100vh;
  }
  div.testitoo{
    display: flex;
    align-items: flex-start;
  }
  span.rotate90numero{
    margin-top: 100px;
    transform: rotate(270deg);
    font-size: rem(160);
    display: inline-block;
  }
  div.pageUnprojet{
    margin-top: 60px;
    height: 100vh;

    div.specificationsProjet{
      display: flex;
      justify-content: space-between;
      border-left: 2px solid $color-black;
      padding-left: 15px;

      div.specificationsGauche{
        display: flex;
        div{
          width: 15vw;
        }
      }
    }
    div.scrollOuNextPrevProjet{
      margin-top: 4vh;
      display: flex;
      justify-content: space-between;
      div{
        display: flex;
        flex-direction: column;
        h6{
          font-weight: $fw-light;
        }
        img{
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          transform: rotate(0deg);
          align-self: flex-start;
          margin-top: rem(10);
        }

        img.flecheFlip{
          transform: rotate(180deg);
          align-self: flex-end;
        }

      }
      span.iconScrollAnimation{
        display: inline-block;
        position: relative;
        height: 80px;
        border-radius: 50%/33%;
        border: solid $color-red 5px;
        width: 50px;

        &:after{
          content:"";
          background-color: black;
          position: absolute;
          display: inline-block;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          top: 50px;
          left: 15px;
          animation: scrollingAnim 1s infinite;
        }

        @keyframes scrollingAnim {
          from {top: 50px;opacity: 1;}
          to {top: 10px;opacity:0}
        }
      }
    }
  }

  section.sectionImagesProjet{
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    background-color: $color-black;

    &:hover *{
      opacity: 0;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity : 0.2;
      &:hover {
        opacity: 1;
      }
    }

    img.one{
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 6;
      transition: all 1s linear;
    }
    img.two{
      grid-column-start: 5;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 6;
      transition: all 0.8s linear;
    }
    img.three{
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 6;
      grid-row-end: 11;
      transition: all 1.2s linear;
    }
    img.four{
      grid-column-start: 4;
      grid-column-end: 8;
      grid-row-start: 6;
      grid-row-end: 11;
      transition: all 1.1s linear;
    }
    img.five{
      grid-column-start: 8;
      grid-column-end: 11;
      grid-row-start: 6;
      grid-row-end: 11;
      transition: all 1.3s linear;
    }
    img.six{
      grid-column-start: 9;
      grid-column-end: 11;
      grid-row-start: 1;
      grid-row-end: 6;
      transition: all 1s linear;
    }


    /* &:hover ~ div.sectionImagesProjet img, ~ img{
       opacity: 0;
     }*/
  }
}