section.hero{
  position: relative;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  //background-color: $color-black;
  background: url("../images/hero.gif") no-repeat;
  background-color: $color-black;

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1{
    text-align: center;
  }

  div.downloadCV{
    h5{
      color: $color-bg-page;
    }
  }

  div.mesProjetsHero{
    position: absolute;
    bottom: rem(80);
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      margin-top: rem(30);
      animation: bobbing 3s ease-in-out infinite;

      @keyframes bobbing {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-20px);
        }
        100% {
          transform: translatey(0px);
        }
      }
    }

  }


  #flecheHero{
    width: rem(31);
    height: rem(19);
  }
}

@media screen and (max-width: $breakpoint-cellphone) {


  section.hero {
    position: relative;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    //background-color: $color-black;
    background: url("../images/hero.gif") no-repeat;
    background-color: $color-black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


  }



  };