header{

    img#openMenu{
     position: fixed;
      top: 7vw;
      right: 7vw;
      z-index: 1;
      transform: translateY(0);
      cursor: pointer;
      transition : transform 1s ease-in-out;
    }
    img#closeMenu{
      position: absolute;
      top: 7vw;
      right: 7vw;
      cursor: pointer;
    }

  .overlay-content{
    position: absolute;
    top: 0;
    left: 10%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .overlay {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    background-color: $color-bg-page;
    transition: width 0.5s;
    overflow: hidden;

    ul{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style-type: none;
      margin: 0;

      li{
        font-size: rem(200);
        color: $gray-400;
        transition: all 0.3s ease-in;
        font-family: $ff-titre;
        white-space: nowrap;
        cursor: pointer;

        &:hover{
          color: $color-red;
        }
      }
    }
  }


  ul.menuSide{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
    top: 40%;
    height: 30vh;
    right: 5%;
    list-style-type: none;
    padding: 0;
    margin: 0;


    li{
      display: flex;
      align-items: center;
      color: white;
      font-size: rem(20);
      text-align: right;
      justify-content: flex-end;

      span{
        order: 2;
        display: inline-block;
        height: 15px;
        width: 15px;
        border: 3px solid $color-red;
        transform : rotate(45deg);
        margin-left: rem(10);
        justify-self: flex-end;
        transition: all 0.2s ease-in-out;
        background-color: transparent;

        &:hover{
          background-color: $color-red;
        }

        &:hover + p{
          opacity: 1;
        }
      }

      p{
        opacity: 0;
        margin: 0;
        padding: rem(5) rem(10);
        background-color: $color-black;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.bodyOverflow{
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 450px) and (min-width: $breakpoint-cellphone) {
  header{

    img#openMenu{
      position: fixed;
      top: 7vw;
      right: 7vw;
      z-index: 1;
      transform: translateY(0);
      cursor: pointer;
      transition : transform 1s ease-in-out;
    }
    img#closeMenu{
      position: absolute;
      top: 7vw;
      right: 7vw;
      cursor: pointer;
    }

    .overlay-content{
      position: absolute;
      top: 0;
      left: 5%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .overlay {
      position: absolute;
      height: 100vh;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      background-color: $color-bg-page;
      transition: width 0.5s;
      overflow: hidden;

      ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
          font-size: rem(80);
          color: $gray-400;
          transition: all 0.3s ease-in;
          font-family: $ff-titre;
          white-space: nowrap;

          &:hover{
            color: $color-red;
          }
        }
      }
    }


  }
}

@media only screen and (max-width: $breakpoint-tablette) and (min-width: 451px) {
  header{

    img#openMenu{
      position: fixed;
      top: 7vw;
      right: 7vw;
      z-index: 1;
      transform: translateY(0);
      cursor: pointer;
      transition : transform 1s ease-in-out;
    }
    img#closeMenu{
      position: absolute;
      top: 7vw;
      right: 7vw;
      cursor: pointer;
    }

    .overlay-content{
      position: absolute;
      top: 0;
      left: 5%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .overlay {
      position: absolute;
      height: 100vh;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      background-color: $color-bg-page;
      transition: width 0.5s;
      overflow: hidden;

      ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
          font-size: rem(100);
          color: $gray-400;
          transition: all 0.3s ease-in;
          font-family: $ff-titre;
          white-space: nowrap;

          &:hover{
            color: $color-red;
          }
        }
      }
    }


  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1250px){
  header{

    img#openMenu{
      position: fixed;
      top: 7vw;
      right: 7vw;
      z-index: 1;
      transform: translateY(0);
      cursor: pointer;
      transition : transform 1s ease-in-out;
    }
    img#closeMenu{
      position: absolute;
      top: 7vw;
      right: 7vw;
      cursor: pointer;
    }

    .overlay-content{
      position: absolute;
      top: 0;
      left: 5%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .overlay {
      position: absolute;
      height: 100vh;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      background-color: $color-bg-page;
      transition: width 0.5s;
      overflow: hidden;

      ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
          font-size: rem(130);
          color: $gray-400;
          transition: all 0.3s ease-in;
          font-family: $ff-titre;
          white-space: nowrap;
        }
      }
    }
  }
}