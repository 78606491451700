div#noScrolling.aproposNoScrolling{
  position: fixed;
  width: 100vw;
section.quiSuis-je{
  height: 100vh;
  text-align: center;
  margin: 0 auto;
  h1{
    height: 10vh;
    color: $color-black;
    margin-bottom: 10vh;
    font-size: rem(120);
    line-height: rem(240);
  }
  div.overflowQualites{
    overflow: hidden;
    height: 80vh;
    margin: 10vh 0;
    div.adjectifsApropos{
      height: 200vh;
      padding: 10vh 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.6s ease-in-out;
      h2{
        display: block;
        font-size: rem(60);
        margin: 0;
        padding: 0;
        font-weight: $fw-regular;
        text-align: center;
        color: transparent;
        text-shadow: 0 0 3px rgba(222,226,230,1);
        transition: all 0.6s ease-in-out;
      }

      .activeH2{
        font-size: rem(140);
        color : $color-red;
      }
      .activeH2little{
        font-size: rem(90);
        color : $gray-400;
      }
    }
  }
}

section.texteAPropos{
  background-color: $color-black;
  height: 100vh;
  overflow: hidden;
  div.heyJeSuis{
    display: flex;
    height: 100%;
    img.maPhotoAPropos{
      width: 50%;
      height: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    div.informationsAPropos{
      margin : 2vh 5vh;
      h2, h3{
        color: $color-red;
      }
      h3{
        padding: rem(30) 0;
        font-family: $ff-secondaire;
      }
      h2{
        font-size: rem(120);
        margin-bottom: rem(20);
        color: transparent;
        -webkit-text-stroke: 1px $color-bg-page;
      }
      p{
        margin: rem(20) 0;
        color: white;
      }
      h5{
        color: white;
      }
      div.mesCompetencesEtLogiciels{
        display: flex;
        div{
          margin-top: rem(40);
        }
        h4{
          font-size: rem(40);
          margin-top: rem(10);
          margin-bottom: rem(15);
        }
        div.colGaucheCompetences{
          margin-right: rem(120);
          img{
            height: 50px;
            margin-right: rem(8);
          }
        }
        div.colDroiteCompetences{
          ul{
            list-style-type: none;
            padding: 0;
            margin: 0;
            li{
              font-size: rem(20);
              color: white;
            }
          }
        }
      }
    }
  }
}
}

@media only screen and (min-width: $breakpoint-cellphone) and (max-width : 450px) {
  div#noScrolling.aproposNoScrolling {
    section.quiSuis-je {
      h1 {
        height: 10vh;
        color: $color-black;
        margin-bottom: 0;
        padding-top: rem(60);
        font-size: rem(60);
        line-height: rem(120);
      }

      div.overflowQualites {

        div.adjectifsApropos {

          h2 {
            display: block;
            font-size: rem(30);
            margin: 0;
            padding: 0;
            font-weight: $fw-regular;
            text-align: center;
            color: transparent;
            text-shadow: 0 0 3px rgba(222, 226, 230, 1);
            transition: all 0.6s ease-in-out;
          }

          .activeH2 {
            font-size: rem(60);
            color: $color-red;
          }

          .activeH2little {
            font-size: rem(45);
            color: $gray-400;
          }
        }
      }
    }
    section.texteAPropos{
      background-color: rgba($color-black, 0.6);
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      div.heyJeSuis{
        display: flex;
        height: 100%;
        position: relative;
        img.maPhotoAPropos{
          position: absolute;
          top: 0;
          right : 0;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: -1;
          object-fit: cover;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
        div.informationsAPropos{
          margin : 2vh 5vh;
          h2, h3{
            color: $color-red;
          }
          h3{
            padding: 0;
            font-size: rem(35);
            font-family: $ff-secondaire;
            width: 80%;
          }
          h2{
            font-size: rem(40);
            margin-bottom: 0;
            color: transparent;
            -webkit-text-stroke: 1px $color-bg-page;
          }
          p{
            margin: rem(20) 0;
            color: white;
            width: 80vw;
            font-size: rem(15);
          }
          h5{
            color: white;
          }
          div.mesCompetencesEtLogiciels{
            display: flex;
            div{
              margin-top: 0;
              margin-bottom: rem(10);
            }
            h4{
              font-size: rem(25);
              margin-top: 0;
              margin-bottom: rem(15);
            }
            div.colGaucheCompetences{
              margin-right: rem(20);
              img{
                height: 30px;
                margin-right: rem(8);
              }
            }
            div.colDroiteCompetences{
              ul{
                list-style-type: none;
                padding: 0;
                margin: 0;
                li{
                  font-size: rem(16);
                  color: white;
                }
              }
              h5{
                display: none;
                box-shadow: none;
                border: solid $color-red 3px;
                position: absolute;
                bottom: 10px;
                left: 10%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width:451px) and(max-width: $breakpoint-tablette)  {
  div#noScrolling.aproposNoScrolling {
    section.quiSuis-je {

      h1 {
        margin-bottom: 5vh;
        font-size: rem(100);
        line-height: rem(200);
      }

      div.overflowQualites {

        div.adjectifsApropos {
          h2 {
            font-size: rem(50);
          }

          .activeH2 {
            font-size: rem(100);
          }

          .activeH2little {
            font-size: rem(75);
          }
        }
      }
    }
    section.texteAPropos{
      background-color: $color-black;
      height: 100vh;
      overflow: hidden;
      div.heyJeSuis{
        display: flex;
        height: 100vh;
        overflow: hidden;
        img.maPhotoAPropos{
          width: 45%;
          height: 100%;
          object-fit: cover;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          opacity: 0.6
        }
        div.informationsAPropos{
          margin : 2vh 2vh;
          h2, h3{
            padding: 0;
          }
          h3{
            font-size: rem(50);
            line-height: rem(70);
          }
          h2{
            font-size: rem(80);
            margin-bottom: rem(0);
          }
          p{
            width: 50vw;
          }
          div.mesCompetencesEtLogiciels{
            display: flex;
            div{
              margin-top: rem(10);
            }
            h4{
              font-size: rem(30);
              margin-top: rem(5);
              margin-bottom: rem(5);
            }
            div.colGaucheCompetences{
              margin-right: rem(20);
              img{
                height: 40px;
              }
            }
            div.colDroiteCompetences{
              ul{
                li{
                }
              }
              h5{
                position: absolute;
                bottom: 0;
                left: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-laptop ) and (min-width:1250px){
  div#noScrolling.aproposNoScrolling{
    section.quiSuis-je{
      h1{
        height: 15vh;
        color: $color-black;
        margin-bottom: 10vh;
        font-size: rem(120);
        line-height: rem(240);
      }
      div.overflowQualites{
        overflow: hidden;
        height: 80vh;
        margin: 10vh 0;
        div.adjectifsApropos{
          h2{
            display: block;
            font-size: rem(50);
          }

          .activeH2{
            font-size: rem(120);
            color : $color-red;
          }
          .activeH2little{
            font-size: rem(80);
            color : $gray-400;
          }
        }
      }
    }

    section.texteAPropos{
      background-color: $color-black;
      height: 100vh;
      overflow: hidden;
      div.heyJeSuis{
        display: flex;
        height: 100%;
        img.maPhotoAPropos{
          width: 50%;
          height: 100%;
          object-fit: cover;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
        div.informationsAPropos{
          margin : 2vh 5vh;
          h2, h3{
            color: $color-red;
          }
          h3{
            padding: rem(0) 0;
            font-size: rem(40);
            font-family: $ff-secondaire;
          }
          h2{
            font-size: rem(100);
            margin-bottom: rem(20);
            color: transparent;
            -webkit-text-stroke: 1px $color-bg-page;
          }
          p{
            margin: rem(10) 0;
            color: white;
          }
          h5{
            color: white;
          }
          div.mesCompetencesEtLogiciels{
            display: flex;
            div{
              margin-top: 0;
            }
            h4{
              font-size: rem(30);
              margin-top: rem(5);
              margin-bottom: rem(10);
            }
            div.colGaucheCompetences{
              margin-right: rem(30);
              img{
                height: 45px;
                margin-right: rem(8);
              }
            }
            div.colDroiteCompetences{
              ul{
                list-style-type: none;
                padding: 0;
                margin: 0;
                li{
                  font-size: rem(20);
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}